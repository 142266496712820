import { getKcContext } from "keycloakify/login";

export type KcContextExtension =
	| { pageId: "login.ftl"; }
	| { pageId: "login-password.ftl"; };

//NOTE: In most of the cases you do not need to overload the KcContext, you can 
// just call getKcContext(...) without type arguments.  
// You want to overload the KcContext only if:  
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { kcContext } = getKcContext<KcContextExtension>({
	mockPageId: "login-page-expired.ftl",
	mockData: [
		{
			pageId: "login.ftl",
			locale: {
				currentLanguageTag: "en",
			},
      registrationDisabled: true,
      realm: {
        registrationAllowed: false,
        loginWithEmailAllowed: true,
      },
			message: { type: 'error', summary: 'hello' }
		},
    {
			pageId: "login-password.ftl",
			locale: {
				currentLanguageTag: "en",
			},
      realm: {
      },
		},
		{
			pageId: "login-update-password.ftl",
			locale: {
				currentLanguageTag: "en",
			},
      realm: {
      },
			isAppInitiatedAction: true,
		},
		{
			pageId: "login-reset-password.ftl",
			locale: {
				currentLanguageTag: "en",
			},
      realm: {
      },
			isAppInitiatedAction: true,
		},
		{
			pageId: "login-page-expired.ftl",
			locale: {
				currentLanguageTag: "en",
			},
      realm: {
      },
			isAppInitiatedAction: true,
		},
		{
			pageId: "login-verify-email.ftl",
			locale: {
				currentLanguageTag: "en",
			},
      realm: {
      },
			isAppInitiatedAction: true,
		},
	]
});

export type KcContext = NonNullable<typeof kcContext>;